@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.active {
    font-weight: bold;
}

.Cafe {
    background-image: url('images/_cafe.png');
    background-size: cover;
}

.Fond {
    background-image: url('images/_fond.png');
    background-size: cover;
}

.Inf_00 {
    background-image: url('images/Inf_00.png');
    background-size: cover;
}

.Footer {
    background-image: url('images/_footer.png');
    background-size: cover;
}
